<template>
  <div class="px-2 mt-1">
    <header-slot />
    <b-nav card-header pills class="m-0">
      <b-nav-item
        v-for="tab in getTabs"
        :key="tab.name"
        :to="{ name: tab.name }"
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        {{ tab.label }}
      </b-nav-item>
    </b-nav>
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view />
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      tabs: [
        {
          name: "paragon-settings-owners",
          label: "Owners Tickets",
        },
        {
          name: "paragon-settings-sections",
          label: "Sections Tickets",
        },
        {
          name: "paragon-settings-motives",
          label: "Motives & Options",
        },
        {
          name: "paragon-assign-teams",
          label: "Assign Teams",
          rol: [1, 2, 17],
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    getTabs() {
      return this.tabs.filter((item) =>
        item.rol ? item.rol.includes(this.currentUser.role_id) : true
      );
    },
  },
};
</script>